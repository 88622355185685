<template >
    <!--  <div class="container">-->
    <div class="widget-box most-viewed">
        <header class="widget-heading">
            <h2>{{ $t("Editor's Picks") }}</h2>
        </header>
        <ul class="most-today">
            <li  v-for="article in editorsPick" :key="article.id">
                <span class="count">0{{article.sort_order+1}}</span>
                <h3>
                    <a :href="article.link" v-if="article.link.startsWith('http')" target="_blank">
                        {{article.headline}}
                    </a>
                    <router-link :to="article.link" v-else>
                        {{article.headline}}
                    </router-link>
                </h3>
            </li>
        </ul>
    </div>
    <!--  </div>-->
</template>

<script>
    export default {
        name: 'EditorPick',
        props: ['editorsPick'],
        data() {
            return {
            }
        },
        mounted() {
        },

        methods: {

        }
    }
</script>
